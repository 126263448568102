@import 'src/styles/services';

.arrow {
  display: grid;
  place-items: center;
  cursor: pointer;

  &.arrowDisabled {
    cursor: default;

    .arrowIcon {
      cursor: default;
    }
  }

  &.arrowHide {
    display: none;
  }

  svg {
    opacity: 0.2;
    transition: trs(opacity);
  }

  @include hover {
    &:not(.arrowDisabled) svg {
      opacity: 1;
    }
  }

  &:first-of-type {
    border-radius: $border-sm 0 0 $border-sm;
  }

  &:last-of-type {
    border-radius: 0 $border-sm $border-sm 0;
  }

  @include media-width-down($screen-xs) {
    display: none;
  }
}

.root {
  display: flex;
  flex-direction: column;

  .moreButton {
    column-gap: 4px;
    padding-inline: 8px;
    font-size: 12px;
    line-height: 2;
    border-radius: 100vw;
  }

  .slider {
    margin: 0;
    width: 100%;
  }

  .sliderSlide {
    min-width: var(--card-min-width, auto);
    max-width: var(--card-max-width, auto);
    width: auto;
  }
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  font-size: 32px;
  line-height: normal;
  font-weight: 700;
  display: flex;

  .icon {
    display: none;
  }

  .text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  sup {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    top: -3px;
    color: $color-grey;
    vertical-align: text-top;
    margin-left: 8px;
  }

  &.uppercaseHeaderVariant {
    text-transform: uppercase;
  }

  &.capitalizeHeaderVariant {
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.itemsCount {
  @include text(md, 500);

  position: relative;
  color: $color-grey;
  margin-left: 8px;

  @include media-width-down($screen-xs) {
    font-size: 10px;
    line-height: 1.2;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  min-height: 32px;
}

.arrows {
  min-width: fit-content;
  margin-left: 16px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

@include media-width-down($screen-xs) {
  .root {
    .slider {
      padding-right: 16px;
    }

    .sliderWrapper {
      width: calc(100% + 16px);
    }
  }

  .header {
    min-height: 24px;
  }

  .title {
    font-size: 22px;
    font-weight: 700;

    .icon {
      display: inline-flex;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      position: relative;
    }

    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 225px;
    }

    sup {
      font-size: 10px;
      top: -2px;
    }
  }

  .titleWithoutButton {
    max-width: 100%;

    .text {
      max-width: 100%;
    }
  }

  .arrows {
    min-width: 0;
  }
}

@include media-width-down($screen-xxs) {
  .title {
    .text {
      max-width: 180px;
    }
  }

  .titleWithoutButton {
    max-width: 100%;

    .text {
      max-width: 100%;
    }
  }

  .arrows {
    min-width: 0;
  }
}
