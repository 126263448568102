@import 'src/styles/services';

.root {
  margin-top: 2px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: url('/assets/casino/tournament/lines.webp') no-repeat top / cover;
  background-color: $color-black-light;
  border-radius: $border-lg;
  cursor: pointer;
  height: 100%;

  &.disabled {
    filter: grayscale(1);
  }

  .place {
    order: 5;
    padding: 6px 8px;
    font-size: 14px;
  }

  .timer {
    order: 5;
  }

  .aboutButton {
    order: 6;
    margin-top: 8px;
    font-weight: 900;
  }
}

.header {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  order: 3;
}

.casino {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.casinoImg {
  max-width: 84px;
}

.bannerImage {
  position: absolute;
  top: 16px;
  bottom: 16px;
  right: 0;
  min-width: 253px;
  z-index: 0;
  aspect-ratio: 310/418;
  order: 2;
}

.type {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.angle {
  width: 260px;
  height: auto;
}

.angleHovered {
  display: none;
}

.angleWrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-top-left-radius: $border-md;
  border-top-right-radius: $border-md;
  order: 1;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-white;
  z-index: 1;
  width: 50%;
  min-height: 60px;
  word-wrap: break-word;
  margin-top: -8px;
  order: 4;
}

.prize {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: $color-red;
  flex: 1;
  z-index: 1;
  order: 4;
}

.root.compact {
  gap: 8px;

  .aboutButton {
    margin-top: 0;
  }

  .header {
    justify-content: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
  }

  .badges {
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
  }

  .bannerImage {
    min-width: unset;
    position: absolute;
    bottom: unset;
    order: 5;
    height: 100%;
    max-height: unset;
    top: 0;
    right: 0;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    min-height: unset;
  }

  .prize {
    text-align: center;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 1.2px;
  }

  .angle {
    width: 200px;
  }

  .type {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}

.root:not(.disabled) {
  @include hover {
    background-color: $color-red;

    .prize {
      color: $color-white;
    }

    &.compact {
      .prize {
        color: $color-yellow;
      }
    }
  }

  &:hover .aboutButton {
    color: red;
    background-color: white;
  }

  &:hover .angle {
    display: none;
  }

  &:hover .angleHovered {
    display: block;
  }
}
