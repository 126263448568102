@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.place {
  z-index: 1;
}

.countdown {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
}

.status {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffffb3;
  z-index: 1;
}

.compact {
  gap: 8px;

  .place {
    text-transform: uppercase;
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }

  .countdown {
    width: 100%;
    background: $color-white;
    color: $color-black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    gap: 4px;
    z-index: 1;
    line-height: 24px;
    font-weight: 900;
  }

  .dots {
    color: $color-red;
  }

  .status {
    text-align: center;
    font-size: 14px;
  }
}

.counter {
  height: 64px;
  width: 100%;
  max-width: 100px;
}
