@import 'src/styles/services';

.root {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 310px;
  padding: 16px;
  border-radius: $border-lg;
  z-index: 1;
  height: 100%;

  &::before {
    pointer-events: none;
    border-radius: $border-lg;
    content: '';
    position: absolute;
    inset: 1px;
    display: block;
    background: url('/assets/promo/images/background.webp') no-repeat top / cover;
    background-color: $color-red;
  }

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 39%, 100% 100%, 0% 100%, 0 57%, 70% 46%, 0 55%, 0 48%);

    @include media-width-down($screen-xs) {
      clip-path: polygon(100% 19%, 100% 100%, 0% 100%, 0 37%, 50% 26%, 0 35%, 0 28%);
    }
  }

  &.darkColor::after {
    background-color: $color-black-light;
  }

  &.lightColor::after {
    background-color: $color-white;
  }

  @include media-width-down($screen-sm) {
    min-width: 160px;
  }
}

.redFilter {
  pointer-events: none;
  content: '';
  position: absolute;
  inset: 1px;
  display: block;
  background-color: $color-red;
  mix-blend-mode: color;
  border-radius: $border-lg;
}

.background {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 1;
  width: 184px;
  z-index: 1;
  margin-bottom: 36px;

  @include media-width-down($screen-xs) {
    width: 75px;
    margin-bottom: 41px;
  }
}

.backgroundImage {
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  place-items: center;
  z-index: 1;
  margin-bottom: 52px;

  @include media-width-down($screen-sm) {
    margin-bottom: 0;
  }
}

.title {
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  line-height: 100%;
  white-space: wrap;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;

  .lightColor & {
    color: $color-black;
  }

  @include media-width-down($screen-sm) {
    padding: 0 10px;
    font-size: 16px;
    line-height: 19px;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: $color-grey-light;
  text-align: center;
  margin-bottom: 16px;

  .lightColor & {
    color: $color-red;
  }

  .cardVariant & {
    color: $color-white;
  }
}

.button {
  padding: 12px 4px;
  margin-top: auto;
}

.buttonText {
  max-width: 100%;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.labels {
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
