.place {
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  z-index: 1;
}

.button {
  z-index: 1;
  align-self: start;
}
