@import 'src/styles/services';

.counter {
  padding: 0 2px;
  font-weight: 700;
  position: relative;
  color: $color-black;
  width: 125px;
  height: 100px;
}

.back {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: calc(100% / 2);
    background: $color-white;
    border-radius: 8px 8px 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 88%, 75% 100%, 0 100%);
    transform: translateX(2px);
  }

  .bottom {
    width: calc(100% - 4px);
    height: calc(100% / 2);
    background: $color-white;
    border-radius: 0 0 8px 8px;
    clip-path: polygon(0 12%, 25% 0, 100% 0, 100% 100%, 0 100%);
    transform: translateX(-2px);
  }
}

.content {
  position: absolute;
  padding: 0 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .top {
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    width: 100%;
    height: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-width-down($screen-md) {
      font-size: 24px;
    }
  }

  .bottom {
    color: $color-red;
    font-size: 16px;
    font-weight: 600;
    width: calc(100% - 8px);
    height: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
}

.counter.mobile {
  width: 60px;

  .top {
    font-size: 24px;
  }
}
