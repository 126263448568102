@import 'src/styles/services';

$offset: 6px;

.root {
  user-select: none;
  display: flex;
  place-items: center;
  font-weight: bold;
  color: $color-black;
  text-transform: uppercase;
}

.icon {
  display: flex;
  place-content: center;
  place-items: center;
  padding: 2px 11px 2px 4px;
  font-size: 18px;
  line-height: 1.1;
  background-color: $color-white;
  clip-path: polygon(0 0, 100% 0, calc(100% - $offset) 100%, 0% 100%);
}

.text {
  position: relative;
  left: -2px;
  padding: 3px 4px 3px 5px;
  font-size: 12px;
  line-height: 1.3;
  background-color: $color-white;
  clip-path: polygon(calc($offset - 3px) 0, 100% 0, 100% 100%, 0 100%);
}
