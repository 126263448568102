@import 'src/styles/services';

.header {
  @include media-width-down($screen-xxs) {
    margin-bottom: -2px;
  }
}

.slide {
  height: auto;
  max-width: 360px;
  min-width: 316px;

  @include media-width-down($screen-xs) {
    min-width: 166px;
  }
}

.wrapper {
  @include media-width-down($screen-xs) {
    width: calc(100% + 16px);
  }
}
