@import 'src/styles/services';

.root {
  .button {
    height: 25px;
    width: 25px;
  }

  .providerCard {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  height: auto;
  font-size: 32px;
  line-height: normal;
  font-weight: 700;
  text-transform: uppercase;

  .text {
    white-space: nowrap;
  }
}

.actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.all {
  column-gap: 4px;
  padding-inline: 8px;
  font-size: 12px;
  line-height: 2;
  border-radius: 100vw;
}

.disabledButton {
  pointer-events: none;
  cursor: default;
}

.arrows {
  min-width: fit-content;
  margin-left: 16px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.arrow {
  display: grid;
  place-items: center;
  cursor: pointer;

  &.disabledButton {
    cursor: default;

    .arrowIcon {
      cursor: default;
    }
  }

  &.arrowHide {
    display: none;
  }

  svg {
    opacity: 0.2;
    transition: trs(opacity);
  }

  @include hover {
    &:not(.disabledButton) svg {
      opacity: 1;
    }
  }

  &:first-of-type {
    border-radius: $border-sm 0 0 $border-sm;
  }

  &:last-of-type {
    border-radius: 0 $border-sm $border-sm 0;
  }

  @include media-width-down($screen-xs) {
    display: none;
  }
}

@include media-width-down($screen-xs) {
  .title {
    font-size: 22px;
    font-weight: 700;

    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 225px;
      display: block;
    }
  }

  .root {
    .sliderWrapper {
      width: calc(100% + 16px);
    }

    .slide {
      width: auto;
    }

    .providerCard {
      width: auto;
      min-width: 166px;
    }

    .arrow {
      display: none;
    }
  }

  .arrows {
    min-width: 0;
  }
}
