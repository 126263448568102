@import 'src/styles/services';

.badge {
  padding: 2px;
  font-size: 11px;
  background: $color-yellow;
}

.vip {
  background: #9a1bba;
}

.casino {
  background: #1b9dba;
}

.leftSkew {
  clip-path: polygon(0 17.5%, 125% 0, 100% 100%, -25% 82.5%);
}

.rightSkew {
  clip-path: polygon(0 0, 125% 17.5%, 100% 82.5%, -25% 100%);
}
